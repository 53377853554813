<template>
  <Dialog
    header="Edit CFP"
    :modal="true"
    v-model:visible="displayBasic"
    :style="{ width: '50vw' }"
  >
    <label class="p-d-block p-my-2" for="conference">Conference</label>
    <Dropdown
      class="p-inputtext-lg"
      v-model="selectedConference"
      :options="conferences"
      style="width: 100%;"
      optionLabel="name"
      optionValue="code"
      placeholder="Select a Conference"
    />

    <label class="p-d-block p-my-2" for="title">Lecture Title</label>

    <InputText
      type="text"
      v-model="title"
      name="title"
      class="p-inputtext-lg"
      placeholder="Title"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="objectives"
      >Objectives: In outline format, what will the attendee learn by attending this session.
    </label>
    <Textarea
      v-model="objective"
      :autoResize="true"
      rows="5"
      cols="30"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="objectives"
      >Description: In sentence format, please describe this presentation in 3-5 sentences.
    </label>
    <Textarea
      v-model="description"
      :autoResize="true"
      rows="5"
      cols="30"
      style="width: 100%;"
    />
    <label class="p-d-block p-my-2" for="objectives"
      >Do you have a co-presenter? If yes, please provide the person's name, credentials, title, employer, phone and email address:
    </label>
    <Textarea
      v-model="copresenter"
      :autoResize="true"
      rows="5"
      cols="30"
      style="width: 100%;"
    />
    <label
      class="p-d-block p-my-2"
      for="objectives"
      style=" margin-top: 20px!important;"
      >To whom will the content of your presentation be geared towards?
    </label>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="IT"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Technical</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="Technical"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Technical</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="Management"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Management</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="periOperative"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >periOperative</label
      >
    </div>
     <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="Surgical Tech"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Surgical Tech</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="Personal Development"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Personal Development</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="Compliance"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Compliance</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="audience"
        value="Imaging"
        v-model="audience"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Imaging</label>
    </div>

    <label
      class="p-d-block p-my-2"
      for="reimbursement"
      style=" margin-top: 20px!important;"
      >Travel expenses or honorariums are available for select participants who have been denied travel reimbursement by their employer on an individual basis. Would you require travel reimbursement or an honorarium in order to participate?
    </label>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="reimbursement"
        value="Travel Reimbursement"
        v-model="reimbursement"
      />
      <label
        style="margin-bottom: 10px;     font-size: 16px;
    color: #868686;"
        class="p-mr-2"
        for="reimbursement1"
        >Travel Reimbursement</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="reimbursement"
        value="Honorarium"
        v-model="reimbursement"
      />
      <label
        class="p-mr-2"
        for="reimbursement1"
        style="    font-size: 16px;
    color: #868686;"
        >Honorarium</label
      >
    </div>
    <div class="p-mb-1">
      <RadioButton
        class="p-mr-1"
        name="reimbursement"
        value="Not Needed"
        v-model="reimbursement"
      />
      <label
        class="p-mr-2"
        for="reimbursement1"
        style="    font-size: 16px;
    color: #868686;"
        >Not Needed</label
      >
    </div>

    <label
      class="p-d-block p-my-2"
      for="record"
      style="    margin-top: 20px!important;"
      >Permission to record presentation?
    </label>
    <RadioButton
      class="p-mr-1"
      name="reimbursement"
      value="Yes"
      v-model="record"
    />
    <label
      class="p-mr-2"
      for="reimbursement1"
      style="    font-size: 16px;
    color: #868686;"
      >Yes</label
    >

    <RadioButton
      class="p-mr-1"
      name="reimbursement"
      value="No"
      v-model="record"
    />
    <label
      class="p-mr-3"
      for="reimbursement2"
      style="    font-size: 16px;
    color: #868686;"
      >No</label
    >

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="closeBasic"
        class="p-button-text"
      />
      <Button
        label="Update"
        icon="pi pi-check"
        @click="useAddConference"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import EditDocument from "../composables/EditDocument";
import GetActiveConferences from "../composables/GetActiveConferences";

export default {
  name: "EditCfpModal",
  props: ["showModal", "user", "presentation", "presentationToEdit"],
  emits: ["modalVisibility", "pushCfpEdit", "pushCfpEditSpeaker"],
  setup(props, { emit }) {
    const {
      getConferences,

      activeConferences,
    } = GetActiveConferences();
    onMounted(async () => {
      console.log("🔥modal mounted");
      activeConferences.value;

      if (activeConferences.value.length < 1) {
        console.log(
          "FETCHING DOCS ON MOUNT!!!!!!!!!!",
          activeConferences.value.length,
          activeConferences.value
        );
        await getConferences();
        console.log("🤖", activeConferences.value);
        activeConferences.value.forEach((item) => {
          conferences.value.push({
            name:
              item.conferenceName + ", " + item.city + " • " + item.displayDate,
            code: item.uid,
          });
        });
      }
    });
    const { useEditDocument, newEditedDoc, editDocError } = EditDocument();

    const useAddConference = async () => {
      const submission = {
        conference: activeConferences.value.find(
          (item) => item.uid == selectedConference.value
        ).conferenceName,
        conferenceUid: selectedConference.value, //get value
        conferenceDate: activeConferences.value.find(
          (item) => item.uid == selectedConference.value
        ).date, //get value
        conferenceDisplayDate: activeConferences.value.find(
          (item) => item.uid == selectedConference.value
        ).displayDate, //get value
        year: activeConferences.value.find(
          (item) => item.uid == selectedConference.value
        ).year, //get value
        conferenceCity: activeConferences.value.find(
          (item) => item.uid == selectedConference.value
        ).city, //get value
        conferenceState: activeConferences.value.find(
          (item) => item.uid == selectedConference.value
        ).state, //get value
        title: title.value,
        objective: objective.value,
        description: description.value,
        copresenter: copresenter.value,
        audience: audience.value,
        reimbursement: reimbursement.value,
        record: record.value,
        // from speakers collection
        address: props.user.address,
        cell: props.user.cell,
        workPhone: props.user.workPhone ? props.user.workPhone : false,
        certifications: props.user.certifications,
        city: props.user.city,
        company: props.user.company,
        education: props.user.education,
        email: props.user.email,
        experience: props.user.experience,
        imageFilePath: props.user.imageFilePath,
        imageUrl: props.user.imageUrl,
        imageCropFilePath: props.user.imageCropFilePath
          ? props.user.imageCropFilePath
          : "",
        imageCropUrl: props.user.imageCropUrl ? props.user.imageCropUrl : "",
        jobTitle: props.user.jobTitle,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        resumeFilePath: props.user.resumeFilePath,
        resumeUrl: props.user.resumeUrl,
        state: props.user.state,
        userUid: props.user.userUid,
        zip: props.user.zip,
        status: status.value,

        // hotel: `${hotelName.value}`,
        // city: `${cityName.value}`,
        // state: `${stateName.value}`,
        // date: `${dateRange.value}`,
        // displayDate: displayDate.value,
        // conferenceName: `${selectedConference.value}`,
        // active: confActive.value,
      };
      console.log(
        props,
        "from parent.....",
        "cfps",
        submission,
        props.presentation.uid
      );
      await useEditDocument("cfps", submission, props.presentation.uid);
      if (!editDocError.value) {
        console.log("it works");
        emit("pushCfpEdit", { ...submission, uid: props.presentation.uid });
        emit("pushCfpEditSpeaker", {
          ...submission,
          uid: props.presentation.uid,
        });

        displayBasic.value = false;
      }
      console.log("cfps", submission, props.presentation.uid);
    };

    const selectedConference = ref("");
    const title = ref("");
    const objective = ref("");
    const description = ref("");
    const copresenter = ref("");
    const audience = ref("");
    const reimbursement = ref("");
    const record = ref("");

    const test = () => {
      console.log(props.user);
    };
    const displayBasic = ref(false);

    const openBasic = () => {
      displayBasic.value = true;
    };
    const closeBasic = () => {
      console.log("start emit...");
      emit("modalVisibility", { status: false });

      displayBasic.value = false;
    };

    watch(
      () => props.showModal,
      (first, second) => {
        console.log(
          "Watch props.showModal function called with args:",
          first,
          second
        );
        displayBasic.value = true;
      }
    );

    watch(
      () => props.presentation,
      (first) => {
        console.log("##############################", first);
        selectedConference.value = first.conferenceUid;
        title.value = first.title;
        objective.value = first.objective;
        description.value = first.description;
        copresenter.value = first.copresenter;
        audience.value = first.audience;
        status.value = first.status;
        reimbursement.value = first.reimbursement;
        record.value = first.record;
      }
    );
    const status = ref("pending");

    const dateRange = ref(null);
    // const conferences = [
    //   { name: "MD Expo", code: "MD Expo" },
    //   { name: "ICE", code: "ICE" },
    //   { name: "OR Today Live", code: "OR Today Live" },
    //   { name: "Periop ConneCT", code: "Periop ConneCT" },
    //   { name: "HTM Mixer", code: "HTM Mixer" },
    // ];
    const conferences = ref([]);
    return {
      selectedConference,
      title,
      objective,
      description,
      copresenter,
      audience,
      reimbursement,
      //   confActive,
      //   hotelName,
      //   cityName,
      //   stateName,
      useEditDocument,
      newEditedDoc,
      editDocError,
      displayBasic,
      openBasic,
      closeBasic,
      dateRange,
      conferences,
      record,

      test,
      useAddConference,
    };
  },
};
</script>

<style></style>
